import React, { useEffect } from 'react'
import GridField from 'grid'
import shortid from 'shortid'
import { withContext } from 'ddiForm'
import { debounce } from 'lodash'
import { Set } from 'immutable'
import {
  getIn,
  empty,
  is,
  formatDateFields,
  formatDollarFields,
  fromJS,
  plainDeepEqual
} from 'utils'
import ErrorBoundary from 'components/ErrorBoundary'
import usePrevious from 'hooks/usePrevious'
import useStateWithCallback from 'hooks/useStateWithCallback'
import memoize from 'memoize-one'
import * as localService from 'services/local'

import { IconButton, Icon, Menu, MenuItem } from '@material-ui/core'

import { setGridColsInLocal } from 'grid/utils'
import {
  getAdditionalDataFromContext,
  tryParseJSON,
  isGridLocalStorageRegistered,
  sortHiddenCols
} from 'pages/SalesOrder/utils'

import { flagActiveGridLayout } from 'pages/SalesOrder/actions'

import { show as showSnackbar } from 'snackbar/actions'
import SuccessAlert from 'pages/SalesOrder/components/SuccessAlert'
import { GET_WAREHOUSE_INVENTORY } from 'pages/SalesOrder/constants'

import WarehouseCheckboxCell from './WarehouseCheckboxCell'
import DynamicWarehousesIsFetchingIndicator from './DynamicWarehousesIsFetchingIndicator'

export const getRowNodeId = data => data.dataId

const addCommasToNumberWithOptionalDecimals = value => {
  if (typeof value === 'number' && value.toLocaleString) {
    return value.toLocaleString('en-US')
  }

  return value
}

const getCellStyle = params => {
  let style = {}
  if (params && params.node && params.node.rowPinned === 'top') {
    style = {
      ...style,
      fontWeight: 'bold'
    }
  }
  if (params?.value < 0) {
    style = {
      ...style,
      color: '#d9534f'
    }
  }
  return style
}

const getInventoryRowData = memoize(
  inventory =>
    inventory && Array.isArray(inventory)
      ? inventory.filter(x => x.dataId !== '*' && !x.isSelected)
      : [],
  plainDeepEqual
)

const getSelectedWarehouseData = memoize(
  inventory =>
    inventory && Array.isArray(inventory)
      ? inventory.filter(x => x.isSelected || x.dataId === '*')
      : null,
  plainDeepEqual
)

const transformInventoryMapToArray = memoize((inventoryMap, key) => {
  const data =
    inventoryMap
      ?.get('companies')
      ?.find(x => x?.get('dataId') === key)
      ?.get('data')
      ?.toJS() || []

  const exception =
    inventoryMap
      ?.get('companies')
      ?.find(x => x?.get('dataId') === key)
      ?.get('exception')
      ?.get('message') || ''

  const meta = inventoryMap?.get('meta')
    ? inventoryMap?.get('meta').toJS()
    : { layoutKey: '', columns: [] }

  return { data, meta, exception }
}, plainDeepEqual)

let warehouseGrid = null
let _isMounted = false

export const WarehouseGrid = ({
  lineItems,
  dispatch,
  form,
  height,
  inventoryMap,
  isMobile = false,
  childIndex,
  userId = '',
  gridLayoutsMap,
  activeGridLayoutsSet,
  gridId,
  selectedRowIndex,
  dataId,
  lineNumber,
  rowId,
  panelIsOpen = false,
  isEditing,
  isFetchingAdditionalData,
  isPosting,
  salesOrderId,
  lineItemsDetailTab
}) => {
  warehouseGrid = React.createRef()
  const gridLayouts = gridLayoutsMap?.toJS ? gridLayoutsMap.toJS() : []
  const inventoryDataObj = transformInventoryMapToArray(inventoryMap, gridId)
  const inventory = inventoryDataObj?.data ? inventoryDataObj.data : []
  const exceptionMessage = inventoryDataObj?.exception
    ? inventoryDataObj.exception
    : null

  const prevInventory = usePrevious(inventory)
  const localStorageGridKey =
    form === 'invoiceInquiry'
      ? 'invoiceInquiry'
      : isMobile
      ? 'salesOrderMobile'
      : 'salesOrder'

  const localStorageGridLayoutTypePrefix = isMobile
    ? 'SOEWarehouseGridMobileLayoutType'
    : 'SOEWarehouseGridLayoutType'

  const warehouseGridActive = Boolean(activeGridLayoutsSet?.get('warehouse'))

  const dynamicColDefs = memoize(
    ({ gridLayoutType, gridLayoutKey, overrideUserLayout = false }) => {
      // gridLayoutKey is needed to re-run this memoized function
      let columnDefs = [
        {
          cellRendererFramework: WarehouseCheckboxCell,
          cellRendererParams: {
            form,
            dispatch,
            isMobile,
            gridId
          },
          field: 'isSelected',
          colId: 'isSelected',
          pinned: 'left',
          lockPinned: true,
          minWidth: 50,
          maxWidth: 50,
          filter: false,
          suppressMenu: true,
          headerName: 'Checkbox',
          headerClass: 'hide-header-text'
        },
        {
          field: 'description',
          colId: 'description',
          headerName: 'Warehouse',
          pinned: 'left',
          lockPinned: true,
          filter: false,
          suppressMenu: true,
          minWidth: 150,
          cellStyle: params => {
            if (params && params.node && params.node.rowPinned === 'top') {
              return {
                fontWeight: 'bold'
              }
            }
            return null
          }
        },
        {
          cellClass: 'align-right',
          headerClass: 'align-right',
          colId: 'onHand',
          field: 'onHand',
          headerName: 'On Hand',
          minWidth: 100,
          filter: false,
          suppressMenu: true,
          cellStyle: getCellStyle,
          valueFormatter: params =>
            addCommasToNumberWithOptionalDecimals(params.value)
        },
        {
          cellClass: 'align-right',
          headerClass: 'align-right',
          colId: 'onShelf',
          field: 'onShelf',
          headerName: 'On Shelf',
          minWidth: 100,
          filter: false,
          suppressMenu: true,
          cellStyle: getCellStyle,
          valueFormatter: params =>
            addCommasToNumberWithOptionalDecimals(params.value)
        },
        {
          cellClass: 'align-right',
          headerClass: 'align-right',
          colId: 'committed',
          field: 'committed',
          headerName: 'Cmttd',
          minWidth: 100,
          filter: false,
          suppressMenu: true,
          cellStyle: getCellStyle,
          valueGetter: params => {
            return params?.data?.committed?.value
          },
          valueFormatter: params => {
            return addCommasToNumberWithOptionalDecimals(params?.value?.value)
          }
        },
        {
          cellClass: 'align-right',
          headerClass: 'align-right',
          colId: 'available',
          field: 'available',
          headerName: 'Avail',
          minWidth: 100,
          filter: false,
          suppressMenu: true,
          cellStyle: getCellStyle,
          valueFormatter: params =>
            addCommasToNumberWithOptionalDecimals(params.value)
        },
        {
          cellClass: 'align-right',
          headerClass: 'align-right',
          colId: 'onPurchaseOrder',
          field: 'onPurchaseOrder',
          headerName: 'On P/O',
          minWidth: 100,
          filter: false,
          suppressMenu: true,
          cellStyle: getCellStyle,
          valueFormatter: params =>
            addCommasToNumberWithOptionalDecimals(params.value)
        },
        {
          colId: 'purchaseOrderExpectedDateEstOrConf',
          field: 'purchaseOrderExpectedDateEstOrConf',
          headerName: 'P/O Expd Dt',
          minWidth: 100,
          filter: false,
          suppressMenu: true,
          cellStyle: getCellStyle,
          cellClass: 'align-center',
          headerClass: 'align-center'
        },
        {
          colId: 'primaryBin',
          field: 'primaryBin',
          headerName: 'Primary Bin',
          hide: true,
          minWidth: 100,
          filter: false,
          suppressMenu: true
        },
        {
          cellClass: 'align-right',
          headerClass: 'align-right',
          colId: 'onStockTransferIn',
          field: 'onStockTransferIn',
          headerName: 'On Trans In',
          minWidth: 100,
          filter: false,
          suppressMenu: true,
          hide: true,
          cellStyle: getCellStyle,
          valueFormatter: params =>
            addCommasToNumberWithOptionalDecimals(params.value)
        },
        {
          cellStyle: { textAlign: 'right' },
          colId: 'warehouseGPCost',
          field: 'warehouseGPCost',
          headerClass: 'align-right',
          headerName: 'Warehouse GP Cost',
          headerTooltip: 'Warehouse GP Cost',
          minWidth: 100,
          valueFormatter: formatDollarFields,
          hide: true
        },
        {
          cellClass: 'align-center',
          headerClass: 'align-center',
          colId: 'stockTransferInExpectedDate',
          field: 'stockTransferInExpectedDate',
          headerName: 'S/T Expd Dt',
          minWidth: 100,
          filter: false,
          suppressMenu: true,
          valueFormatter: formatDateFields,
          cellStyle: params => {
            if (params && params.node && params.node.rowPinned === 'top') {
              return {
                fontWeight: 'bold'
              }
            }
            return null
          },
          hide: true
        },
        {
          cellClass: 'align-right',
          headerClass: 'align-right',
          colId: 'rank',
          field: 'rank',
          headerName: 'Rank',
          minWidth: 100,
          hide: true,
          cellStyle: {
            fontWeight: 'bold'
          }
        }
      ]

      const hiddenUserCols = tryParseJSON(localService.get(`grids-${userId}`))

      let selectedLayoutFromMeta =
        gridLayoutType === 'company'
          ? gridLayouts.find(x => x.layoutKey === 'SalesOrderInventory')
              ?.company
          : gridLayouts.find(x => x.layoutKey === 'SalesOrderInventory')
              ?.default

      if (gridLayoutType === 'company' && selectedLayoutFromMeta === null) {
        selectedLayoutFromMeta = gridLayouts.find(
          x => x.layoutKey === 'SalesOrderInventory'
        )?.default
      }

      const selectedColsFromMeta = selectedLayoutFromMeta?.bands && Array.isArray(selectedLayoutFromMeta.bands) ? selectedLayoutFromMeta.bands[0].columns : []

      if (!selectedColsFromMeta?.length) {
        return columnDefs
      }

      if (
        (gridLayoutType === 'default' &&
          (!warehouseGridActive || overrideUserLayout)) ||
        (gridLayoutType === 'company' &&
          (!warehouseGridActive || overrideUserLayout))
      ) {
        columnDefs = columnDefs.reduce((acc, next) => {
          /* remove pinned and lockPinned from col configs if not specified by the API */
          const { pinned, lockPinned, ...other } = next // eslint-disable-line
          if (
            selectedColsFromMeta.find(x => x.key === next.field) ||
            next.field === 'dataId'
          ) {
            const isPinned =
              selectedColsFromMeta.find(x => x.key === next.field)?.value
                ?.fixed || false

            const displayedColumnConfig = isPinned
              ? {
                  ...other,
                  lockPinned: true,
                  pinned: 'left',
                  hide: false
                }
              : {
                  ...other,
                  hide: false
                }

            acc = acc.concat(displayedColumnConfig)
          } else {
            acc = acc.concat({ ...other, hide: true })
          }

          return acc
        }, [])

        const sortedKeyed = selectedColsFromMeta.reduce(
          (acc, next) => {
            const { key } = next
            acc[key] = next.value.position + 1
            return acc
          },
          { dataId: 0 }
        )

        columnDefs = sortHiddenCols(columnDefs).sort((a, b) => {
          if (sortedKeyed[a.colId] > sortedKeyed[b.colId]) {
            return 1
          }

          if (sortedKeyed[a.colId] < sortedKeyed[b.colId]) {
            return -1
          }

          return 0
        })
      } else if (gridLayoutType === 'user' || warehouseGridActive) {
        if (
          hiddenUserCols &&
          hiddenUserCols?.[localStorageGridKey]?.warehouse
        ) {
          columnDefs = columnDefs.reduce((acc, next) => {
            acc = acc.concat({
              ...next,
              hide:
                (next.field !== 'dataId' &&
                  hiddenUserCols?.[localStorageGridKey]?.warehouse?.includes(
                    next.field
                  )) ||
                false
            })
            return acc
          }, [])
        }

        const userOrdered =
          localService.get(
            `gridOrdered-${userId}-${localStorageGridLayoutTypePrefix}`
          ) || {}

        if (
          userOrdered &&
          typeof userOrdered === 'object' &&
          userOrdered !== null &&
          Object.keys(userOrdered).length
        ) {
          columnDefs = columnDefs.sort((a, b) => {
            if (userOrdered[a.field] > userOrdered[b.field]) {
              return 1
            }

            if (userOrdered[a.field] < userOrdered[b.field]) {
              return -1
            }

            return 0
          })
        }
      }

      return columnDefs
    }
  )

  const [state, setState] = useStateWithCallback({
    anchorEl: null,
    gridLayoutKey: shortid.generate(),
    columnDefs: dynamicColDefs({
      gridLayoutKey: shortid.generate(),
      gridLayoutType:
        localService.get(`${localStorageGridLayoutTypePrefix}-${userId}`) ||
        'company'
    }),
    inventory
  })

  const prevColumnDefs = usePrevious(state.columnDefs)
  const prevGridLayoutType = usePrevious(state.gridLayuoutType)
  const prevGridLayoutKey = usePrevious(state.gridLayoutKey)
  const prevLineNumber = usePrevious(lineNumber)
  const prevRowId = usePrevious(rowId)
  const prevGridId = usePrevious(gridId)
  const prevPanelIsOpen = usePrevious(panelIsOpen)
  const prevDataId = usePrevious(dataId)
  const prevIsEditing = usePrevious(isEditing)
  const prevInventoryMap = usePrevious(inventoryMap)
  const prevSalesOrderId = usePrevious(salesOrderId)
  const prevLineItemDetailsTab = usePrevious(lineItemsDetailTab)

  const handleDisplayWarehouseData = debounce(async () => {
    if (panelIsOpen && _isMounted && !isPosting && !isFetchingAdditionalData) {
      if (rowId === 'blankrow' || !dataId || !lineNumber || !gridId) {
        /* 
          this means that the product is going through 
          the process of being added, probably we are
          waiting on the initial results of the API transaction
        */
        await setState({
          ...state,
          inventory: []
        })

        return
      }

      let inventoryData = {
        inventory: {
          companies: [{ dataId, data: [] }]
        }
      }

      if (inventoryDataObj?.data?.length) {
        await setState({
          ...state,
          inventory: inventoryDataObj?.data
        })
        return
      }
      if (childIndex) {
        // console.log(additionalDataMap)
        // const temp = additionalDataMap.get()
        const temp = lineItems
          .get(selectedRowIndex)
          .get('components')
          .find(x => x.get('lineNumber') === childIndex)
        if (temp) {
          setState({
            ...state,
            inventory:
              temp
                ?.get('inventory')
                ?.get('companies')
                ?.find(x => x.get('dataId') === gridId)
                ?.get('data')
                ?.toJS() || []
          })
        }
        // debugger
      } else {
        try {
          // debugger
          inventoryData = await dispatch({
            type: GET_WAREHOUSE_INVENTORY.TRY,
            meta: { form, thunk: true },
            payload: { gridId, lineNumber }
          })
          // debugger
        } catch (e) {
          // debugger
          setState({
            ...state,
            inventory: []
          })
        } finally {
          // debugger
          setState({
            ...state,
            inventory:
              inventoryData?.inventory?.companies?.find(
                x => x?.dataId === gridId
              )?.data || []
          })
        }
      }
    }
  }, 100)

  useEffect(() => {
    _isMounted = true
    handleDisplayWarehouseData()

    return () => {
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    if (
      (lineNumber && prevLineNumber && lineNumber !== prevLineNumber) ||
      (rowId && prevRowId && rowId !== prevRowId) ||
      (gridId && prevGridId && gridId !== prevGridId) ||
      (panelIsOpen !== prevPanelIsOpen && panelIsOpen) ||
      (is.bool(isEditing) &&
        is.bool(prevIsEditing) &&
        isEditing !== prevIsEditing) ||
      (dataId && dataId !== prevDataId) ||
      (lineItemsDetailTab !== prevLineItemDetailsTab &&
        lineItemsDetailTab === 'Inventory')
    ) {
      /* update or load any saved warehouse data */
      handleDisplayWarehouseData()
    }
  }, [
    lineNumber,
    prevLineNumber,
    gridId,
    prevGridId,
    panelIsOpen,
    prevPanelIsOpen,
    dataId,
    prevDataId,
    isEditing,
    prevIsEditing,
    rowId,
    prevRowId,
    lineItemsDetailTab,
    prevLineItemDetailsTab
  ])

  useEffect(() => {
    if (
      lineNumber &&
      prevLineNumber &&
      lineNumber === prevLineNumber &&
      inventory &&
      prevInventory &&
      Array.isArray(inventory) &&
      Array.isArray(prevInventory) &&
      prevInventory.length &&
      !inventory.length &&
      dataId
    ) {
      /* 
        this basically should only happen after Copy Order or cancel edit functions 
        maybe look into inventoryMap?.get('companies') ????
      */
      handleDisplayWarehouseData()
    }
  }, [inventory, prevInventory, lineNumber, prevLineNumber])

  useEffect(() => {
    if (prevSalesOrderId && salesOrderId !== prevSalesOrderId) {
      handleDisplayWarehouseData()
    }
  }, [salesOrderId, prevSalesOrderId])

  useEffect(() => {
    if (!plainDeepEqual(prevColumnDefs, state.columnDefs)) {
      const layoutRules = state.columnDefs.reduce(
        (acc, next) => {
          if (next.hide) {
            acc.hide = acc.hide.concat(next.field)
          } else {
            acc.show = acc.show.concat(next.field)
          }
          return acc
        },
        { show: [], hide: [] }
      )

      /* this is important */
      if (warehouseGrid?.current?.columnApi) {
        warehouseGrid.current.columnApi.setColumnsVisible(
          layoutRules.show,
          true
        )
        warehouseGrid.current.columnApi.setColumnsVisible(
          layoutRules.hide,
          false
        )
      }

      /*
        update localStorage, this will NOT get updated by the grid's onColumnVisible 
        callback function in app/grid/index 
      */

      const gridRegisteredInLocal = isGridLocalStorageRegistered(
        localService.get(`grids-${userId}`),
        localStorageGridKey
      )

      if (gridRegisteredInLocal) {
        /* this will error if the grid hasn't been registered in storage already */
        setGridColsInLocal(
          {
            form,
            userId,
            propertyName: 'warehouse'
          },
          layoutRules.hide
        )
      }
    }
  }, [
    state.columnDefs,
    prevColumnDefs,
    state.gridLayoutType,
    prevGridLayoutType,
    state.gridLayoutKey,
    prevGridLayoutKey
  ])

  useEffect(() => {
    if (state?.inventory?.length && !plainDeepEqual(inventory, prevInventory)) {
      setState({
        ...state,
        inventory: inventoryDataObj?.data ? inventoryDataObj.data : []
      })
    }
  }, [inventory, prevInventory])

  useEffect(() => {
    if (!plainDeepEqual(inventoryMap, prevInventoryMap)) {
      setState({
        ...state,
        inventory: inventoryDataObj?.data ? inventoryDataObj.data : []
      })
    }
  }, [inventoryMap, prevInventoryMap])

  const displayGridUpdateNotification = () => {
    if (state.gridLayoutKey) {
      dispatch(
        showSnackbar({
          message: {
            message: 'Layout Updated',
            type: 'info',
            timer: 5000,
            id: state.gridLayoutKey,
            component: SuccessAlert(state.gridLayoutKey)
          }
        })
      )
    }
  }

  const removeActiveGridLayout = () => {
    dispatch(
      flagActiveGridLayout(form, {
        gridName: 'warehouse',
        action: 'remove'
      })
    )
  }

  async function resetGridLayout(layoutType) {
    await removeActiveGridLayout()

    setState(
      {
        ...state,
        anchorEl: null,
        gridLayoutKey: shortid.generate(),
        columnDefs: dynamicColDefs({
          gridLayoutType: layoutType,
          gridLayoutKey: shortid.generate(),
          overrideUserLayout: true
        })
      },
      () => {
        localService.set(
          `${localStorageGridLayoutTypePrefix}-${userId}`,
          layoutType
        )

        displayGridUpdateNotification()
      }
    )
  }

  const onColumnVisibleCb = params => {
    if (!activeGridLayoutsSet?.get('warehouse')) {
      dispatch(
        flagActiveGridLayout(form, {
          gridName: 'warehouse',
          action: 'add'
        })
      )
    }
  }

  const saveUserColumnsInLocal = () => {
    if (warehouseGrid?.current?.columnApi) {
      const ordered = warehouseGrid.current.columnApi.getColumnState()
      if (ordered && Array.isArray(ordered)) {
        localService.set(
          `gridOrdered-${userId}-${localStorageGridLayoutTypePrefix}`,
          ordered.reduce((acc, next, idx) => {
            const key = next.colId
            if (key) {
              acc[key] = idx
            }
            return acc
          }, {})
        )
      }
    }
  }

  const onDragStopped = params => {
    saveUserColumnsInLocal()
    onColumnVisibleCb()
  }

  const saveLayoutForUser = () => {
    removeActiveGridLayout()

    setState(
      {
        ...state,
        anchorEl: null,
        gridLayoutKey: shortid.generate(),
        columnDefs: dynamicColDefs({
          gridLayoutType: 'user',
          gridLayoutKey: shortid.generate()
        })
      },
      () => {
        localService.set(
          `${localStorageGridLayoutTypePrefix}-${userId}`,
          'user'
        )

        displayGridUpdateNotification()
      }
    )
  }

  const getContextMenuItems = params => {
    return [
      {
        name: 'Layout',
        subMenu: [
          {
            name: 'Reset To Default',
            action: e => resetGridLayout('default')
          },
          {
            name: 'Set To Company',
            action: e => resetGridLayout('company')
          },
          {
            name: 'Save For User',
            action: saveLayoutForUser
          }
        ]
      }
    ]
  }

  const mobileMenuOpen = Boolean(state.anchorEl)

  const openMobileMenu = event =>
    setState({
      ...state,
      anchorEl: event.currentTarget
    })

  const closeMobileMenu = () =>
    setState({
      ...state,
      anchorEl: null
    })

  // console.log('inventory', inventory, state.inventory)
  let gridHeight = state.inventory.length
    ? state.inventory.length * 28 + 52
    : 150

  gridHeight = isMobile ? height : gridHeight

  if (exceptionMessage) {
    return (
      <div style={{ position: 'relative ' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: 15
          }}
        >
          <div
            className="alert alert-warning"
            style={{
              backgroundColor: '#fcf8e3',
              borderColor: '#faebcc',
              padding: 5,
              margin: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Icon size="small" style={{ marginRight: 5 }}>
              warning
            </Icon>
            <span style={{ fontSize: '12px', lineHeight: '16px' }}>
              {exceptionMessage}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ErrorBoundary>
      <div style={{ position: 'relative' }}>
        {isMobile ? (
          <>
            <IconButton
              style={{
                position: 'absolute',
                top: '-33px',
                right: '20px',
                padding: '10px'
              }}
              onClick={openMobileMenu}
            >
              <Icon style={{ color: '#517b9c' }}>settings_applications</Icon>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={state.anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={mobileMenuOpen}
              onClose={closeMobileMenu}
            >
              <MenuItem onClick={e => resetGridLayout('default')}>
                Reset To Default
              </MenuItem>
              <MenuItem onClick={e => resetGridLayout('company')}>
                Set To Company
              </MenuItem>
              <MenuItem onClick={saveLayoutForUser}>
                Save Layout For User
              </MenuItem>
            </Menu>
          </>
        ) : (
          <DynamicWarehousesIsFetchingIndicator
            fetchingEntity={isFetchingAdditionalData}
          />
        )}

        <div style={{ overflow: 'hidden', width: '100%' }}>
          <GridField
            rowData={getInventoryRowData(state.inventory)}
            pinnedTopRowData={getSelectedWarehouseData(state.inventory)}
            columnDefs={state.columnDefs}
            defaultColDef={{
              suppressMovable: false,
              lockPinned: true
            }}
            getRowNodeId={getRowNodeId}
            height={gridHeight}
            fullWidth
            allowFieldChooser
            form={form}
            width="100%"
            style={{ width: '100%' }}
            id="warehouse"
            getContextMenuItems={getContextMenuItems}
            ref={warehouseGrid}
            key={state.gridLayoutKey}
            columnDefVisibilityManagedExternally
            onDragStopped={onDragStopped}
            onColumnVisibleCb={onColumnVisibleCb}
          />
        </div>
      </div>
    </ErrorBoundary>
  )
}

const inventoryDefaults = fromJS({
  companies: [
    {
      data: [],
      dataId: null
    }
  ],
  meta: { layoutKey: '', columns: [] }
})
export const contextObj = {
  lineItems: form => getIn(form, 'fields.lineItems.rowData') || empty,
  gridLayoutsMap: form => getIn(form, 'meta.gridLayouts') || empty,
  inventoryMap: form =>
    getAdditionalDataFromContext(form, 'inventory', inventoryDefaults),
  isEditing: form => form.get('isEditing') || false,
  isFetchingAdditionalData: form =>
    form.get('isFetchingAdditionalData') || false,
  isPosting: form => form.get('isPosting') || false,
  childIndex: form => {
    const selectedRowIndex = getIn(form, 'fields.lineItems.childIndex')

    if (is.number(selectedRowIndex)) {
      return selectedRowIndex
    }

    return null
  },
  selectedRowIndex: form => {
    const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')

    if (is.number(selectedRowIndex)) {
      return selectedRowIndex
    }

    return null
  },
  activeGridLayoutsSet: form => getIn(form, 'activeGridLayouts') || Set(),
  salesOrderId: form => getIn(form, 'fields.dataId.value') || '',
  lineItemsDetailTab: form => getIn(form, 'ui.lineItemsDetailTab') || ''
}

export default withContext(contextObj)(WarehouseGrid)
